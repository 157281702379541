import ReactPlayer from 'react-player'
import { stringifyQueryString } from '@kaliber/sanity-routing/queryString'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'

import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'

import { LineWhite } from '/features/pageOnly/Line'
import { Icon } from '/features/buildingBlocks/Icon'
import { ButtonLinkBlue } from '/features/buildingBlocks/Button'
import { MediaWindow } from '/features/pageOnly/MediaWindow'

import styles from './HeroHome.css'

import searchIcon from '/images/icons/search.raw.svg'

export function HeroHome({ heroImage, heroVideoSources, loopingVideoSources, introduction, title, layoutClassName = undefined }) {
  return (
    <section data-x='hero' className={cx(styles.component, layoutClassName)}>
      <div className={styles.heroContainer}>
        <HeadingWithVideoAndText
          layoutClassName={styles.headingWithVideoAndTextLayout}
          {...{ title, heroVideoSources, loopingVideoSources, introduction }}
        />
        <HeroImage layoutClassName={styles.heroImageLayout} {...{ heroImage }} />
      </div>

      <Searchbox layoutClassName={styles.searchboxLayout} />
    </section>
  )
}

function HeadingWithVideoAndText({ title, heroVideoSources, loopingVideoSources, introduction, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentHeadingWithVideoAndText, layoutClassName)}>
      <div className={styles.headingSection}>
        <Heading layoutClassName={styles.headingLayout} {...{ title }} />
        <div className={styles.smallVideoContainer}>
          {loopingVideoSources && (
            <Video sources={loopingVideoSources} layoutClassName={styles.videoLayout} />
          )}
        </div>
      </div>

      <div className={styles.videoAndTextWithLine}>
        <div className={styles.videoSection}>
          {heroVideoSources && (
            <Video sources={heroVideoSources} layoutClassName={styles.videoLayout} />
          )}
        </div>
        <div className={styles.textWithLineSection}>
          <IntroTextWithLine layoutClassName={styles.introTextWithLineLayout} {...{ introduction }} />
        </div>
      </div>
    </div>
  )
}

function HeroImage({ heroImage, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentHeroImage, layoutClassName)}>
      <div className={styles.imageContainer}>
        {heroImage && <MediaWindow aspectRatio={9 / 16} distanceOverride={50} image={heroImage} />}
      </div>
    </div>
  )
}

function Heading({ title, layoutClassName = undefined }) {
  const { titleLineOne, titleLineTwo } = title || {}

  return (
    <h1 className={cx(styles.componentHeading, layoutClassName)}>
      <span className={styles.headingWhiteLine}>{titleLineOne}</span>
      <span className={styles.headingBlueLine}>{titleLineTwo}</span>
    </h1>
  )
}

function IntroTextWithLine({ introduction, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentIntroTextWithLine, layoutClassName)}>
      <div className={styles.lineContainer}>
        <LineWhite withEnterAnimation customStartPoint={0.4} layoutClassName={styles.lineLayout} />
      </div>
      <p className={styles.introText}>
        {introduction}
      </p>
    </div>
  )
}

function Searchbox({ layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const language = useLanguage()
  const searchInputRef = React.useRef(undefined)

  return (
    <div className={cx(styles.componentSearchbox, layoutClassName)}>
      <form data-x='job-search-form' className={styles.searchboxForm} onSubmit={handleSubmit}>
        <input
          ref={searchInputRef}
          type='text'
          placeholder={__`search-jobs`}
          className={styles.searchboxInput}
        />
        <button data-x='click-to-search' aria-label='search button' className={styles.searchButton} onClick={handleSubmit}>
          <Icon icon={searchIcon} layoutClassName={styles.iconLayout} />
        </button>
      </form>
      <ButtonLinkBlue href={routeMap.app.jobs({ language })} dataX='link-to-jobs' label={__`all-jobs`} layoutClassName={styles.jobsLinkLayout} />
    </div>
  )

  function handleSubmit(e) {
    e.preventDefault()
    const searchString = searchInputRef.current.value
    const queryString = stringifyQueryString({ searchString })

    window.location.href = `${routeMap.app.jobs({ language })}?${queryString}`
  }
}

function Video({ sources, layoutClassName = undefined }) {
  const isMounted = useRenderOnMount()
  const { src: url, ref: videoSizeRef } = useVideoQualityLevel({
    sources: {
      1080: sources?.quality1080 || null,
      720: sources?.quality720 || null,
      540: sources?.quality540 || null,
      360: sources?.quality360 || null
    }
  })

  return (
    <div ref={videoSizeRef} className={cx(styles.componentVideo, layoutClassName)}>
      {isMounted && (
        <ReactPlayer
          width="100%"
          height="100%"
          playing
          muted
          playsinline
          loop
          // eslint-disable-next-line @kaliber/layout-class-name
          className={styles.player}
          {...{ url }}
        />
      )}
    </div>
  )
}
